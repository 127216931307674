import React, { PropsWithChildren } from 'react';

interface IProps {
  href: string;
  title?: string;
  className?: string;
  ariaLabel?: string;
}

export function ExternalLink(props: PropsWithChildren<IProps>) {
  const { href, title = 'Opens in a new window/tab', className, ariaLabel, children } = props;

  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      title={title}
      className={className}
      aria-label={ariaLabel}
      style={{ overflowWrap: 'break-word' }}
    >
      {children ?? href}
    </a>
  );
}

