import { DocumentModal } from 'components/Documents/DocumentModal';
import { ExternalLink } from 'components/Shared/ExternalLink';
import { useScrollToTopEffect } from 'hooks/useScrollToTopEffect';
import React from 'react';
import { useHistory } from 'react-router';

export function CounterTerrorismPage() {
  useScrollToTopEffect();

  const history = useHistory();
  const handleClose = () => {
    history.push('/');
  };

  return (
    <DocumentModal
      title="Counter Terrorism security advice for Licensed Premises"
      content={
        <>
          <p className="intro">
            The UK faces a real threat from terrorism. Terrorist groups attack vulnerable people and
            sites to create a climate of fear and intimidation and cause maximum harm to the
            economy. Places where crowds of people gather such as shopping centres, sports stadia,
            hotels, bars, pubs and clubs are easily accessible and attractive targets.
          </p>
          <p>
            The owners of Licensed Premises can mitigate against the risk to their businesses, their
            employees and customers by introducing robust security measures, policies and procedures
            which in turn will make it difficult for terrorists to plan and carry out their attacks.
          </p>
          <p>
            <strong>
              <u>Martyns Law (Protect Duty)</u>
            </strong>{' '}
            – Martyn’s Law will help further improve public safety, enhancing our national security
            and reducing the risk to the public from terrorism by the protection of public premises
            and events. It will place a requirement on those responsible for certain premises and
            events to consider the threat from terrorism and implement appropriate and proportionate
            mitigation measures. The legislation will ensure responsible persons are prepared, ready
            to respond and know what to do in the event of an attack. Better protection will be
            delivered through enhanced security systems, staff training, and clearer processes. More
            can be found here:{' '}
            <ExternalLink href="https://homeofficemedia.blog.gov.uk/2022/12/19/martyns-law-factsheet/" />
          </p>
          <p>
            <strong>
              <u>Publicly Accessible Locations</u>
            </strong>{' '}
            – The National Counter Terrorism Security Office (NaCTSO) has created a Publicly
            Accessible Locations (PALs) Guidance page, which can be found on the Protect UK website
            or by clicking the following link -
            <ExternalLink href="https://www.protectuk.police.uk/advice-and-guidance/risk/publicly-accessible-locations-pals-guidance" />{' '}
            It covers different types of Attack methodology, a Good Housekeeping guide (including a
            handy checklist), Physical Security, through to Cyber Security advice & Guidance.
          </p>
          <p>
            <strong>
              <u>Recognising Terrorist Threats</u>
            </strong>{' '}
            – The Centre for the Protection of National Infrastructure (CPNI) has produced a guide
            which contains helpful information for security professionals (Security Industry
            Authority staff) to improve their ability to recognise threats, their key components and
            other attack indicators and to respond appropriately. More information from the CPNI can
            be found here:{' '}
            <ExternalLink href="https://www.protectuk.police.uk/advice-and-guidance/risk/recognising-terrorist-threats" />
          </p>
          <p>
            The Government has outlined its support for pubs, cafes and restaurants and other
            businesses and made more efficient the application process for pavement licencing to
            facilitate al-fresco dining and drinking and other hospitality and entertainment
            enterprises. As a result we will likely see a change in how publicly accessible
            locations like high streets look, feel and operate. These changes will have an impact on
            the security risk profile for these locations. The Centre for the Protection of National
            Infrastructure (CPNI) and National Counter Terrorism Security Office (NaCTSO) have
            produced guidance to help Authorities consider the threats and develop holistic
            mitigation strategies which can be found here:{' '}
            <ExternalLink href="https://www.npsa.gov.uk/blog/physical-security/high-street-hospitality-protective-security-considerations" />
          </p>
          <p>
            <strong>
              <u>See, Check and Notify (SCaN)</u>
            </strong>{' '}
            – aims to help businesses and organisations maximise safety and security using their
            existing resources. Your people are your biggest advantage in preventing and tackling a
            range of threats, including criminal activity, unlawful protest and terrorism. SCaN
            training empowers your staff to correctly identify suspicious activity and know what to
            do when they encounter it. In addition to this, the skills your staff learn will help
            them to provide an enhanced customer experience. It helps ensure that individuals or
            groups seeking to cause your organisation harm are unable to get the information they
            need to plan their actions. You can find out more about the SCaN modules and how to
            access them on the following website:{' '}
            <ExternalLink href="https://www.npsa.gov.uk/see-check-and-notify-scan" />
          </p>
          <p>
            <strong>
              <u>Project SERVATOR</u>
            </strong>{' '}
            – a strategic method of policing to deter, detect and disrupt a wide range of criminal
            activity, from pickpocketing and property theft to terrorism, which provides a
            reassuring presence for the public and the communities they serve.{' '}
            <ExternalLink href="https://www.counterterrorism.police.uk/servator/" />
          </p>
          <p>
            <strong>
              <u>RUN, HIDE, TELL</u>
            </strong>{' '}
            – What to do in the event of a terrorist attack. Guidance issued by CT Policing for
            members of the public. In the unlikely event of a terrorist attack, remember to follow
            RUN HIDE TELL – guidance which can be applied to many situations and places. More can be
            found by clicking the following link, including a handy leaflet{' '}
            <ExternalLink href="https://www.protectuk.police.uk/advice-and-guidance/response/run-hide-tell" />
          </p>
          <p>
            <strong>
              <u>Action Counters Terrorism (ACT) E - Awareness</u>
            </strong>{' '}
            – <ExternalLink href="https://www.protectuk.police.uk/group/84?type=catalog" /> is a
            free to use, 45 minute e-learning package that covers how to spot the signs of
            suspicious behaviour and what to do if an attack should take place.
          </p>
          <p>
            <strong>
              <u>Business Continuity</u>
            </strong>{' '}
            – this provides advice and guidance on how the business will operate following an
            incident and how it can to return to ‘business as usual’ in the quickest possible time.
            A business continuity plan (BCP) need not be specific to terrorist incidents and applies
            to any major disruption such as fire, flooding, or power fault.
            <br />
            <ExternalLink href="https://www.protectuk.police.uk/advice-and-guidance/risk/managing-risk-and-business-continuity" />
            <br />
            <ExternalLink href="https://assets.publishing.service.gov.uk/media/5a7b283de5274a34770e9d01/Business_Continuity_Managment_Toolkit.pdf" />
          </p>
          <p>
            <strong>
              <u>Evacuation, Invacuation, Lockdown, Protected Spaces</u>
            </strong>{' '}
            – Those with responsibility for Publicly Accessible Locations are advised to consider
            the threat from a broad range of terrorist methodologies. Such methodologies may include
            Vehicle as a Weapon, Marauding Attacks with either bladed weapons or firearms and
            improvised explosive devices etc. Planning your emergency response should such an
            incident occur would likely require a range of emergency responses, including a
            consideration of emergency evacuation, ‘invacuation’ (an inward evacuation) and lockdown
            procedures, alongside the use of protected spaces. This document is intended to provide
            guidance for Publicly Accessible Locations in the planning and implementation of their
            site’s emergency response. This document provides generic advice for all Publicly
            Accessible Locations: venues, buildings and events.{' '}
            <ExternalLink href="https://www.protectuk.police.uk/evacuation-invacuation-lockdown-protected-spaces" />
          </p>
          <p>
            <strong>
              <u>Vehicles Used as Weapons</u>
            </strong>{' '}
            – The threats from vehicles range from vandalism to sophisticated or aggressive attacks
            by determined criminals or terrorists. As well as a convenient method to deliver an
            explosive device, an additional attack methodology is using a vehicle as a weapon (VAW).
            A vehicle by itself can also be used with hostile intent to breach a perimeter, ram and
            damage infrastructure, or as a weapon to injure and kill people. This is referred to as
            a ‘vehicle as a weapon’ attack. The use of a vehicle as a weapon is a low complexity
            methodology and has been used by terrorists to target crowded places and publicly
            accessible locations. A broad range of vehicles can cause significant loss of life and
            serious injury. <ExternalLink href="https://www.protectuk.police.uk/vehicle-weapon" />
          </p>
          <p>
            <strong>
              <u>Hostile reconnaissance</u>
            </strong>{' '}
            – is the term given to the information gathering phase by those individuals or groups
            with malicious intent. The following page provides an understanding of why and how
            hostile reconnaissance is conducted, and the principles of how to disrupt threats during
            the reconnaissance phase, along with practical measures on how to reduce the
            vulnerability of your site:{' '}
            <ExternalLink href="https://www.cpni.gov.uk/disrupting-hostile-reconnaissance-0" />
          </p>
          <p>
            For specific advice relating to your business premises, contact your local Counter
            Terrorism Security Advisor (CTSA) who works with businesses to identify and assess sites
            that may be vulnerable to terrorist or extremist attack. The CTSA can be contact through
            the non-emergency telephone number - 101.
          </p>
        </>
      }
      onClose={handleClose}
    />
  );
}

